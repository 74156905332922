import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import Images from "../img/Images";

const Partners = (props) => (
  <StaticQuery
    query={graphql`
      query LogoQuery {
        home: datoCmsHome {
          logoDescription
					logoLink
        }
      }
    `}
    render={(data) => {
      const home = data.home;
      return (
        <div className="partners-container">
          <div className="centre-container">
            <div className="inner grid-ten -centre">
              <div className="logo-container">
								<a href="https://www.aemc.gov.au/" target="_blank" className="single-logo -aemc">
								<img src={Images.aemcLogo} alt="AEMC"></img>
								</a>
								<a href="https://aemo.com.au/en" target="_blank" className="single-logo -aemo">
								<img src={Images.aemoLogo}alt="AEMO"></img>
								</a>
								<a href="https://aer.gov.au" target="_blank" className="single-logo -aer">
								<img src={Images.aerLogo} alt="AER"></img>
								</a>
							</div>
              <div className="logo-description" dangerouslySetInnerHTML={{ __html: home.logoDescription }} />
							{home.logoLink && 
								<a className="data-portal-cta" href={home.logoLink} target="_blank" >Learn more <img src={Images.topRightBlue}  alt='check out' /></a>
							}
            </div>
          </div>
        </div>
      );
    }}
  />
);

export default Partners;
