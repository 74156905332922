import React from 'react'
import PropTypes from "prop-types"
import KeyContentBlock from "../components/keyContentBlock";
import ReactMarkdown from 'react-markdown';


const CustomOption = ({ innerProps, isDisabled }) =>
	!isDisabled ? (
		<div {...innerProps}>{/* your component internals */}</div>
	) : null;

class MessagesBlock extends React.Component {
	constructor(props) {
		super(props)
	}

	getHash = (title) => {
		return title
		.replace(/[^\w\s]/gi, '')
		.toLowerCase()
		.replace(/ /gi, '-');
	}

	fixData = (data) => {
		let newArray = []
		let that = this
		data.map((obj, i, arr) => {
			if (obj.keyMessage) {

				let innerArr = arr.slice(i);

				let inner = [];
				for (let k = 1; k < innerArr.length; k++) {
					if (innerArr[k].__typename == 'DatoCmsHeading') {
						break;
					} else {
						inner.push(innerArr[k])
					}
				}

				newArray.push({
					title: obj.keyMessage,
					data: inner,
					hash: that.getHash(obj.keyMessage)
				})
			}
		})
		return newArray;
	}

	render() {

		const { bodyContent } = this.props.content
		const newData = this.fixData(bodyContent)
		return (
			<div className="key-messages-home">
				<div className="key-inner">
					<div className="key-intro-container">
						<div className="grid-container -centred">
							<div className="intro-block">
								<h2 className="intro-heading">{this.props.keyMessagesTitle}</h2>
								<div className="intro-copy">
								<div dangerouslySetInnerHTML={{ __html: this.props.keyMessagesDescription }}></div>
								</div>
							</div>
						</div>
					</div>
					{newData.map((obj, i) => {
						return <KeyContentBlock
							number={i + 1}
							content={obj}
							key={i}
							hash={this.props.hash}
							path={this.props.path}
						/>
					})}
				</div>
			</div>
		)
	}
}

MessagesBlock.propTypes = {
	content: PropTypes.object
}

MessagesBlock.defaultProps = {
	content: {}
}

export default MessagesBlock
