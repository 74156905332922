import React from 'react'
import { Link, graphql } from 'gatsby'
import Masonry from 'react-masonry-component'
import { HelmetDatoCms } from 'gatsby-source-datocms'
import Layout from "../components/layout"
import HomeHero from "../components/home-hero"
import FeatureArticles from '../components/featureArticles'
import Images from '../img/Images'
import KeyMessages from "../components/key-messages"
import Header from '../components/header'
import Partners from "../components/partners"
import Infographics from "../components/infographics"
import Banners from "../components/banners"

const IndexPage = ({ data: { home }, location }) => {
	return (
		<Layout>
			<Header
				home
				location={location}
				title={home.title}
			/>
			<HomeHero
				data={home}
			/>
			<Partners/>
			<FeatureArticles />
			<Infographics/>
			{/* <KeyMessages location={location} /> */}
			<Banners />
		</Layout>
	)
}

export default IndexPage

export const query = graphql`
	query HomeQuery {
		home: datoCmsHome {
			seoMetaTags {
				...GatsbyDatoCmsSeoMetaTags
			}
			title
			description
		}
	}
`
