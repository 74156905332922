import React from "react";
import PropTypes from "prop-types";
import { StaticQuery, graphql, Link } from "gatsby";
import Images from "../img/Images";
import ReactMarkdown from "react-markdown";

const FeatureArticle = ({ content }) => {
	return (
		<div className="article_main_container">
			<div className="diagonal_container">
				<Link to={`/${content.slug}`}>
					<div className="inside">
						<div className="inner">
							<h2>{content.title}</h2>
							<div
								dangerouslySetInnerHTML={{
									__html: content.description,
								}}
							/>
						</div>

						<div className="feature_link">
							<p>READ MORE</p>
						</div>
					</div>
				</Link>
			</div>
		</div>
	);
};

const FeatureArticles = (props) => (
	<StaticQuery
		query={graphql`
			query featureArticleListQuery {
				articles: allDatoCmsFeatureArticle(sort: { fields: [position], order: ASC }) {
					edges {
						node {
							title
							id
							description
							slug
							showCards
						}
					}
				}
				homeContent: datoCmsHome {
					featureArticlesTitle
					featureArticlesDescription
				}
			}
		`}
		render={(data) => {
			const articles = data.articles.edges;
			const featureArticle = data.homeContent;
			const display = articles.map(function(obj, i) {
				if (obj.node.showCards) {
					return <FeatureArticle content={obj.node} key={i} />;
				} else {
					return "";
				}
			});
			return (
				<div className="feature-article-container">
					<div className="centre-container">
						<div className="inner grid-full -centre">
							{featureArticle.featureArticlesDescription &&
								<div className="intro-block">
									<h2 className="intro-heading">
										{featureArticle.featureArticlesTitle}
									</h2>
									<div className="intro-copy">
										<div
											dangerouslySetInnerHTML={{
												__html: featureArticle.featureArticlesDescription,
											}}
										/>
									</div>
								</div>
							}
							<div className="article-container">{display}</div>
						</div>
					</div>
				</div>
			);
		}}
	/>
);

FeatureArticles.propTypes = {
	// siteTitle: PropTypes.string,
};

FeatureArticles.defaultProps = {
	// siteTitle: ``,
};

export default FeatureArticles;
